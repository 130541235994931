<template>
  <div>
    <h2 class="mt-3">Modifications Rendez-Vous</h2>

    <div class="container py-4">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <!-- Première partie (à gauche) -->
            <div class="col-md-6">
              <h5>1. Modifier Les Rendez-Vous Sélectionnés</h5>
              <button class="btn btn-danger btn-sm mb-4 " @click="deleteappointment">Supprimer le RDV</button>
              <table class="table">
                <thead>
                  <tr>
                    <th>Service</th>
                    <th>Durée</th>
                    <th>Prix</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr  v-for="(service, index) in selectedServices" :key="index">
                    <td>{{ service.name }}</td>
                    <td>{{ service.time }} </td>
                    <td>{{ service.price }} €</td>
                    <td>
                      <button class="btn btn-danger btn-sm" @click="removeService(index)">Supprimer</button>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Total</strong></td>
                    <td>{{ totalDuration }} minutes</td>
                    <td>{{ totalPrice }} €</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <select class="form-select" v-model="selectedCategory">
                <option value="">Sélectionnez une catégorie</option>
                <option v-for="category in categories" :key="category" :value="category">{{ category }}</option>
              </select>

              <select class="form-select mt-2" v-model="selectedPrestation">
                <option value="">Sélectionnez plus de prestation</option>
                <option v-for="prestation in filteredPrestations" :key="prestation._id" :value="prestation._id">{{
                  prestation.service }}</option>
              </select>
              <button class="btn btn-primary btn-sm mt-2 mb-4 button1" @click="addSelectedService">Ajouter la prestation
                sélectionnée</button>             
            </div>



            <!-- Deuxième partie (à droite) -->
            
    <div class="col-md-6">
      <h5>2. Sélectionnez Une Nouvelle Date et l'Heure</h5>

      <div class="mb-2">
        <button v-if="!loading" class="button1" style="margin-right: 5px;" @click="changeWeeknegative()">Semaine Precedente</button>
        <button v-if="!loading" class="button1" @click="changeWeek()">Semaine Suivante</button>
        <!-- Other UI elements to display available time slots -->
      </div>

      <div mt-4 v-if="loading" >
        <img style="height: 200px; margin-top: 45px;" src="https://i.pinimg.com/originals/6b/e0/89/6be0890f52e31d35d840d4fe2e10385b.gif">
      </div>

      <div class="table-responsive" v-else>
        <table class="table">
          <thead>
            <tr>
              <th v-for="day in days" :key="day">{{ day }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-for="day in days" :key="day">
                <div class="time-slot" v-for="timeslot in groupedTimeSlots[day]" :key="timeslot.start">
                  <div class="time-slot-item">
                    <button @click="handleButtonClick(timeslot), showForm = true" class="mt-1 button1">{{ timeslot.time }}</button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  

          </div>
        </div>
      </div>
    </div>

    <div class="overlay" v-if="showForm"></div>

    
    <!-- Afficher le formulaire comme une popup -->
    <div class="popup" v-if="showForm">
      <p v-if="successMessage">{{ successMessage }}</p>
      <form @submit.prevent="submitForm" class="container">

  <div>
    <div class="form-group">
    <label for="firstName">Prénom</label>
    <input type="text" class="form-control" id="firstName" v-model="firstName" required>
  </div>
  <div class="form-group">
    <label for="lastName">Nom</label>
    <input type="text" class="form-control" id="lastName" v-model="lastName" required>
  </div>
  <div class="form-group">
    <label for="email">Email</label>
    <input type="email" class="form-control" id="email" v-model="email" required>
  </div>
  <div class="form-group">
    <label for="phone">Téléphone</label>
    <input type="tel" class="form-control" id="phone" v-model="phone" required>
  </div>
  <p>Dates Et Heures du Nouveau RDV : {{ selectedDateTime }}</p>
  <p>Prestation(s) Sélectionnée(s) :
    <ul>
      <li v-for="(service, index) in selectedServices" :key="index">{{ service.name }}</li>
    </ul>
  </p>
  <p>Temps : {{ totalDuration }} minutes</p>
  <p>Tarifs : {{ totalPrice }} €</p>
</div>
  <button type="submit" class="btn btn-primary">Envoyer</button>
  <button style="margin-left: 2px;" type="button" class="btn btn-danger " @click="showForm = false">Annuler</button>
</form>

    </div>
    <div class="popup" v-if="showSuccessPopup">
  <p>{{ successMessage }}</p>
  <button  type="button" class="btn btn-primary" @click="handleOkButtonClick">OK</button>
</div>
  </div>
</template>

<script>
import axios from 'axios';

import { Settings } from 'luxon';

Settings.defaultLocale = 'fr';
import { DateTime } from 'luxon';


export default {
  components: {
  },
  data() {
    return {
      loading: false,
      showSuccessPopup: false,
      weekOffset: 0,
      selectedDateTime: null,
      selectedServices: [],
      selectedTime: '',
      selectedDate: new Date(),
      prestationsList: [],
      selectedCategory: '',
      isConfirmed: false,
      days: [],
      groupedTimeSlots: {},
      timeslot: [],
      startTimes: [],
      selectedPrestation: '',
      currentDate: new Date(),
      totalCost: 0,
      date: null,
      showForm: false,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      successMessage: "",

    };
  },
  computed: {
    
    totalDuration() {
     return this.calculateTotalDuration();
    },

    totalPrice() {
    const totalPrice = this.selectedServices.reduce((total, service) => total + parseInt(service.price), 0);
    console.log('totalPrice:', totalPrice);
    return totalPrice;
}
,
    categories() {
      // Créez une liste unique de catégories
      return [...new Set(this.prestationsList.map(prestation => prestation.categorie))];
    },
    filteredPrestations() {
      // Filtrez les prestations en fonction de la catégorie sélectionnée
      return this.prestationsList.filter(prestation => prestation.categorie === this.selectedCategory);
    },

    currentWeekStartDate() {
      const firstDay = new Date(this.currentDate);
      firstDay.setDate(this.currentDate.getDate() - this.currentDate.getDay());

      // Formatez la date au format 'YYYY-MM-DD'
      const year = firstDay.getFullYear();
      const month = (firstDay.getMonth() + 1).toString().padStart(2, '0');
      const day = firstDay.getDate().toString().padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    currentWeekDays() {
      const days = [];
      const currentDate = new Date(this.currentWeekStartDate);
      for (let i = 0; i < 7; i++) {
        days.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return days;
    },
  },
  created() {
    const serviceId = this.$route.params.serviceId;
    console.log('Service ID:', serviceId);

    if (serviceId) {
      this.fetchPrestations().then(() => {
        const serviceDetails = this.getServiceDetailsById(serviceId);
        console.log('Service Details:', serviceDetails);

        if (serviceDetails) {
          this.selectedServices.push(serviceDetails);
          console.log('Selected Services:', this.selectedServices);
        }
      });
    }
  },
  methods: {

    calculateTotalDuration() {
    const totalDuration = this.selectedServices.reduce((total, service) => total + this.convertToMinutes(service.time), 0);

    if (totalDuration > 0) {
      console.log('Total duration CalculateTotalDuration :', totalDuration);
      this.fetchAvailableTimeSlots(this.weekOffset, totalDuration);
    } else {
      this.loading = false;
    }

    return totalDuration;
  },

    async getAppointmentDetails() {
    try {
      await this.fetchPrestations();
      const appointmentId = this.$route.params.id;
      const response = await axios.get(`https://naturel-joli-432d8cf9d3d8.herokuapp.com/appointments/${appointmentId}`);
      this.appointment = response.data;
      console.log('Appointment:', this.appointment);

      // Réinitialisez la liste selectedServices avant d'ajouter de nouveaux services
      this.selectedServices = [];

      // Récupérez les détails du service pour chaque service dans l'appointment
      for (const service of this.appointment.service) {
        const serviceDetails = this.prestationsList.find((prestation) => prestation.service === service.name);

        console.log('Service Details:', serviceDetails);

        // Si les détails du service sont trouvés, ajoutez-le à la liste selectedServices
        if (serviceDetails) {
          this.selectedServices.push({
            name: serviceDetails.service,
            time: serviceDetails.duree, // Utilisez la propriété correcte de votre modèle de données
            price: serviceDetails.prix, // Utilisez la propriété correcte de votre modèle de données
          });
        }
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des détails du rendez-vous :', error);
    }
  },

    // Ajoutez cette méthode dans la section "methods" de votre composant Vue

    async submitForm() {
      const startDateTime = new Date(this.selectedDateTime);
      const appointmentId = this.$route.params.id;
// Convertir totalDuration en minutes
const totalDurationMinutes = parseInt(this.totalDuration);
console.log('service selctionner ',this.selectedServices)

// Calculer la date de fin en ajoutant totalDurationMinutes à la date de début
const endDateTime = new Date(startDateTime.getTime() + totalDurationMinutes * 60000);
    const updatedData = {
      title: this.appointment.title,
      start: startDateTime.toISOString(),
      end: endDateTime.toISOString(),
      service: this.selectedServices.map(service => ({ name: service.name, time: service.time, price: service.price  })),
      email: this.email,
  price  : this.totalPrice,
  nom : this.lastName,
  prenom : this.firstName,
  phone: this.phone,
      
      
    };
    console.log('Updated Appointment Data:', updatedData);
    console.log('Updated Appointment Data:', updatedData);

    console.log('Updated Appointment Data:', updatedData);

    // Make an HTTP PUT request to update the appointment
    axios.put(`https://naturel-joli-432d8cf9d3d8.herokuapp.com/appointments/${appointmentId}`, updatedData)
      .then(response => {
        console.log('Appointment updated successfully:', response.data);
        // Optionally, you can refresh the events on the calendar after updating
        this.showForm = false;
        this.successMessage = "Rendez-vous Mis A Jours avec succès. Un e-mail vous a été envoyé.";
        this.showSuccessPopup = true;
      })
      .catch(error => {
        console.error('Error updating appointment:', error);
        // Handle errors as needed
      });
  },

  async deleteappointment() {
  const appointmentId = this.$route.params.id;

  try {

    const deleteResponse = await axios.delete(`https://naturel-joli-432d8cf9d3d8.herokuapp.com/appointments/${appointmentId}`);
    console.log('Appointment deleted successfully:', deleteResponse.data);


    // Fetch the appointment details, including the title
    const appointmentResponse = await axios.get(`https://naturel-joli-432d8cf9d3d8.herokuapp.com/appointments/${appointmentId}`);
    const appointmentDetails = appointmentResponse.data;

    // Extract the name and surname from the title
    const [prenom, nom] = appointmentDetails.title.split(' ');
    console.log('prenom:', prenom);
    console.log('nom:', nom);

    // Fetch the client details to get the email
    const clientResponse = await axios.get(`https://naturel-joli-432d8cf9d3d8.herokuapp.com/clients?nom=${prenom}&prenom=${nom}`);
    const matchingClient = clientResponse.data[0];

    console.log('Matching client:', matchingClient);
    console.log('nom:', nom);
    console.log('prenom:', prenom);

    if (!matchingClient) {
      console.error('No matching client found for deletion.');
      return;
    }

    // Extract the email from the client details
    const email = matchingClient.email;

    const emailData = {
      to: email,
      cc: 'natureletjoli@gmail.com',
      subject: 'Annulation RDV Confirmation',
      body: 'Votre Rendez-vous a été annulé avec succès',
    };

    const emailResponse = await axios.post('https://naturel-joli-432d8cf9d3d8.herokuapp.com/sendConfirmationEmail', emailData);
    console.log('Email confirmation sent successfully:', emailResponse.data);

    // Delete the appointment after retrieving the email
   

    // Optionally, you can refresh the events on the calendar after updating
    this.successMessage = "Rendez-vous Supprimé avec succès";
    this.showSuccessPopup = true;
  } catch (error) {
    console.error('Error deleting appointment:', error);
    // Handle errors as needed
  }
}
,




  resetForm() {
    // Réinitialisez les champs du formulaire après la création réussie
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.phone = '';
    this.selectedTime = '';
    this.selectedServices = [];
    // ... autres champs du formulaire
  },

  handleButtonClick(timeslot) {
    this.selectedDateTime = DateTime.fromISO(timeslot.start, { zone: 'Europe/Paris' }).toFormat('yyyy-MM-dd HH:mm');
    // ... autres traitements si nécessaires
  },

    convertToMinutes(time) {
      // Convertit le format "HH:mm" en minutes
      const parts = time.split(":");
      return parseInt(parts[0]) * 60 + parseInt(parts[1]);
    },


    async fetchAvailableTimeSlots(weekOffset, time) {
  try {
    this.loading = true;
    
   
    const response = await axios.get(`https://naturel-joli-432d8cf9d3d8.herokuapp.com/available-timeslots?weekOffset=${weekOffset}&time=${time}`);
   
    if (response.status === 200) {
      this.loading = false;
      const data = response.data;
      console.log('weekoffset', this.weekOffset)
      setTimeout(() => {
        this.groupedTimeSlots = this.groupTimeSlots(data);
        this.days = Object.keys(this.groupedTimeSlots);
        console.log('time slots:', this.groupedTimeSlots);
      }, 0);
      this.loading = false;
    } else {
      console.error('Error fetching available time slots:', response.status);
    }
  } catch (error) {
    console.error('Error fetching available time slots:', error);
  }
},

    async changeWeek() {
      const newWeekOffset = this.weekOffset += 1;
      console.log('New week offset:', newWeekOffset);
      await this.fetchAvailableTimeSlots(newWeekOffset, this.totalDuration);
    },
    async changeWeeknegative() {
      const newWeekOffset = this.weekOffset -= 1;
      console.log('New week offset:', newWeekOffset);
      await this.fetchAvailableTimeSlots(newWeekOffset, this.totalDuration);
    },


    groupTimeSlots(timeSlots) {
  const groupedSlots = {};
  timeSlots.forEach(slot => {
    const formattedDate = this.formatDate(slot.start);
    if (!groupedSlots[formattedDate]) {
      groupedSlots[formattedDate] = [];
    }
    groupedSlots[formattedDate].push({
      start: slot.start, // Garder la date complète
      formattedStart: formattedDate, // Ajouter la date formatée
      time: this.formatTime(slot.start),
    });
  });
  console.log('groupedSlots:', groupedSlots);
  return groupedSlots;
}
,

    formatDate(dateTime) {
      const date = DateTime.fromISO(dateTime, { zone: 'Europe/Paris' });
      const formattedDate = date.toFormat('cccc dd/MM');
      return formattedDate;
    },
    formatTime(dateTime) {
      const parisTimezone = 'Europe/Paris';
      const date = DateTime.fromISO(dateTime, { zone: parisTimezone });
      return date.toFormat('HH:mm');
    },



    async fetchPrestations() {
      try {
       
        console.log('fetchprestation timeslot called')
        const response = await axios.get('https://naturel-joli-432d8cf9d3d8.herokuapp.com/prestations');
        this.prestationsList = response.data;
        console.log('Prestations List:', this.prestationsList);
      } catch (error) {
        console.error('Erreur lors de la récupération des prestations:', error);
      }
    },

    selectDate(date) {
      // Gérez la logique de sélection de date ici
      console.log('Date sélectionnée:', date);
    },
    selectHour(hour) {
      // Gérez la logique de sélection d'heure ici
      console.log('Heure sélectionnée:', hour);
    },
    previousWeek() {
      this.currentDate.setDate(this.currentDate.getDate() - 7);
    },
    nextWeek() {
      this.currentDate.setDate(this.currentDate.getDate() + 7);
    },

    handleOkButtonClick() {
    // Close the success popup
    this.showSuccessPopup = false;

    // Redirect to the home page
    this.$router.push({ name: 'home' }); // Replace 'home' with the actual name of your home page route
  },


    addSelectedService() {
      // Utilisez this.selectedPrestation pour obtenir l'ID de la prestation sélectionnée
      // Recherchez les détails de la prestation en utilisant cet ID
      const serviceDetails = this.getServiceDetailsById(this.selectedPrestation);

      if (serviceDetails) {
        this.selectedServices.push(serviceDetails);
      }

      // Réinitialisez la sélection du dropdown
      this.selectedPrestation = '';
    },
    getServiceDetailsById(serviceId) {
      console.log('getServiceDetailsById called with ID:', serviceId);
      const service = this.prestationsList.find((prestation) => prestation._id == serviceId); // Use loose equality here
      console.log('prestationsList:', this.prestationsList);

      if (service) {
        console.log('Found service:', service);
        return {
          name: service.service,
          time: service.duree,
          price: service.prix,
        };
      } else {
        console.log('Service not found');
        return null;
      }
    },

    addService() {
      // Implement your logic to add services here
    },
    removeService(index) {
      // Supprimer la prestation à l'index donné de selectedServices
      this.selectedServices.splice(index, 1);
      
    },
    confirmRdv() {
      // Implement your logic to confirm appointments here
    },
    login() {
      // Implement your login logic here
    },
    signup() {
      // Implement your signup logic here
    },
    continueAsGuest() {
      // Implement your "continue as guest" logic here
    },
  },
  mounted() {
    this.getAppointmentDetails();
    this.fetchPrestations();
    
  },
};
</script>

<style scoped>
.calendar {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.calendar-header {
  font-weight: bold;
}

.calendar-days {
  display: flex;
  justify-content: space-between;
}

.day {
  width: 100px;
  margin: 5px;
}

button {
  
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
}
</style>


<style scoped>
.button1 {
  background-color: #cd825b;
  border: 1px solid;
  color: white;
  text-align: bottom;
}

.button1:hover{
  background-color: #cd825b;
  color: #efe5db;
}

.time-slot-item {
  display: inline-block;
  margin-right: 10px;
  /* Espacement entre les div */
  /* Ajoutez d'autres styles CSS pour votre classe time-slot-item si nécessaire */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Couleur de fond semi-transparente */
  z-index: 999;
  /* Place la superposition au-dessus de tout le contenu */
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-slot-item {
    vertical-align: top;
  }
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  /* Couleur de fond du popup */
  z-index: 1000;
  /* Place le popup au-dessus de la superposition */
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}


table {
    width: 100%;
  }

  th {
    padding-right: 10px;
  }

  td {
    vertical-align: top;
  }

  .time-slot {
    margin-bottom: 10px; /* Add some space between time slots */
  }

  /* Media query for smaller screens (e.g., phones) */
  @media (max-width: 768px) {
    th,
    td {
      width: 25%; /* Limit each column to 25% of the table width */
      box-sizing: border-box; /* Include padding and border in the width */
    }
  }

</style>