<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <a class="navbar-brand" href="#" style="color: rgb(205, 130, 91); font-family: 'Pacifico', cursive;">Naturel&Joli</a>
        <button
          @click="toggleMenu"
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" :class="{ 'show': menuVisible }" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" style="color: rgb(205, 130, 91);" to="/">Accueil</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" style="color: rgb(205, 130, 91);" to="/prestation">Prestation</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" style="color: rgb(205, 130, 91);" to="/rendez-vous">Rendez-vous</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <router-view/>
  
  <footer class="bg-light mt-auto">
    <!-- Conteneur pour le pied de page -->
    <div class="container py-3">
      <!-- Contenu du pied de page -->
      <div class="row">
        <div class="col-md-3">
          <h4>Horaires</h4>
          <p>Lundi - Samedi: 9h - 20h<br>Dimanche: Fermé</p>
        </div>
        <div class="col-md-3">
          <h4>Contact</h4>
          <p>Téléphone: 07 61 16 93 08<br>Instagram: Natureletjoli <br><i class="fa-solid fa-house"></i> 257 Rue de Paris, 95150 Taverny </p>
        </div>
        <div class="col-md-3">
          <img class="" src="./asset/logo.png" alt="Logo" style="width: 175px; height: auto;">
        </div>
        <div class="col-md-3">
          <h4>Fidélité</h4>
          <p>Votre fidélité récompensée -20% à votre 11e Visite Demandez votre carte.</p>
        </div>
      </div>
      <div class="row">
      <div class="col-md-12 text-center">
        <p class="footer-text">All Rights Reserved &copy; Naturel&Joli Réalisé par <a href="https://datafuse.fr" target="_blank" class="footer-link">DATAFUSE</a></p>
        <router-link class="nav-link" style="color: rgb(205, 130, 91);" to="/admin">Admin</router-link>
      </div>
    </div>
    </div>
  </footer>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}


</style>
<script>
export default {
  data() {
    return {
      menuVisible: false
    };
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    }
  }
};
</script>