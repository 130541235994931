<template>
  <div class="container py-4">
    <h2 class="mt-3 mb-3">Nos Prestations</h2>
    <div class="row">
      <div
        class="col-md-6 mb-3"
        v-for="(category, index) in uniqueCategories"
        :key="index"
      >
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ category }}</h5>
            <table class="table">
              <thead>
                <tr>
                  <th>Nom du Service</th>
                  <th>Durée</th>
                  <th>Prix</th>
                  <th>RDV</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(service, serviceIndex) in servicesByCategory(category).slice(0, showMore ? undefined : 5)"
                  :key="serviceIndex"
                >
                  <td>{{ service.service }}</td>
                  <td>{{ service.duree }}</td>
                  <td>{{ service.prix }}€ </td>
                  <td>
                    <button class="btn  btn-sm button1" @click="redirectToRdv(service.id)">RDV</button>
                  </td>
                </tr>
              </tbody>
            </table>
            <a style="color: black;"
              href="javascript:void(0)"
              @click="toggleShowMore"
              v-if="servicesByCategory(category).length > 5"
            >
              {{ showMore ? "Masquer" : "Afficher plus de prestations" }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';


export default {
  name: 'EighthSection',
  data() {
    return {
      categoriesData: [],
      showMore: false,
      cart: [],
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    uniqueCategories() {
      // Créez un tableau de catégories uniques en parcourant les données brutes
      const uniqueCategories = [...new Set(this.categoriesData.map(item => item.categorie))];
      return uniqueCategories;
    },
  },
  methods: {

    addToCart(service) {
      // Ajoutez la prestation sélectionnée au panier
      this.cart.push(service);
      this.redirectToCart();
    },
    redirectToRdv(serviceId) {
      // Redirigez l'utilisateur vers la page de rendez-vous en passant le serviceId comme paramètre
      this.$router.push({ name: 'rendezvous', params: { serviceId } });
    },
    fetchData() {
      // Faites une requête GET pour récupérer les prestations depuis l'URL
      axios.get('https://naturel-joli-432d8cf9d3d8.herokuapp.com/prestations')
        .then((response) => {
          // Mettez à jour les données du composant avec les résultats de la requête
          this.categoriesData = response.data; // Assurez-vous que la structure des données correspond à votre modèle
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des prestations :', error);
        });
    },
    servicesByCategory(category) {
      // Retourne tous les services pour une catégorie donnée
      return this.categoriesData.filter(item => item.categorie === category);
    },
    toggleShowMore() {
      // Inversez l'état de "showMore" pour afficher ou masquer plus de prestations
      this.showMore = !this.showMore;
    },
  },
};
</script>

<style scoped>
.button1 {
background-color: #efe5db;
border: 1px solid;
color: white;
text-align: bottom;
}

.button1:hover{
  background-color: #cd825b;
  color: #efe5db;
}
</style>
