import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Prestation from '../views/Prestation.vue'
import RendezVous from '../views/RendezVous.vue'
import Admin from '../views/Admin.vue'
import Client from '../views/Client.vue'
import prestationAdmin from '../views/prestationAdmin.vue'
import AppointmentDetails from '../views/AppointmentDetails.vue';
import login from '../views/login.vue';
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/admin/client',
    name: 'client',
    component: Client,
    beforeEnter: (to, from, next) => {
      // Check if the user is logged in
      if (isLoggedIn()) {
        // Allow access to the admin page
        next();
      } else {
        // Redirect to the login page if not logged in
        next('/login');
      }
    },
  },
  {
    path: '/modification-rendez-vous/:id',
    name: 'appointmentDetails',
    component: AppointmentDetails,
    props: true,
  },
  {
    path: '/admin/prestation',
    name: 'prestationAdmin',
    component: prestationAdmin,
    beforeEnter: (to, from, next) => {
      // Check if the user is logged in
      if (isLoggedIn()) {
        // Allow access to the admin page
        next();
      } else {
        // Redirect to the login page if not logged in
        next('/login');
      }
    },
  },
  {
    path: '/rendez-vous/:serviceId',
    name: 'rendezvous',
    component: RendezVous,
    props: true,
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    beforeEnter: (to, from, next) => {
      // Check if the user is logged in
      if (isLoggedIn()) {
        // Allow access to the admin page
        next();
      } else {
        // Redirect to the login page if not logged in
        next('/login');
      }
    },
  },
  {
    path: '/prestation',
    name: 'prestation',
    component: Prestation
  },
  {
    path: '/rendez-vous',
    name: 'rendez-vous',
    props: true,
    component: RendezVous
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})




export default router

function isLoggedIn() {
  return store.state.isLoggedIn;
}