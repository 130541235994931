<template>
    <div class="mt-4" >
      <h2> WELCOME ! 🤗</h2>  
    </div>
        <div style="margin-bottom: 250px; margin-top: 100px;" class=" row main-content bg-success text-center">
          <div style="background-color: rgb(220,220,220);" class="col-md-4 text-center company__info">
            <img src="../asset/logo.png" class="company_title" >
          </div>
          <div class="col-md-8 col-xs-12 col-sm-12 login_form">
            <div class="container-fluid">
              <div class="row">
                <form @submit.prevent="handleSubmit" class="form-group">
                  <div class="row">
                    <input v-model="username" type="text" name="username" id="username" class="form__input" placeholder="Username">
                  </div>
                  <div class="row">
                    <input v-model="password" type="password" name="password" id="password" class="form__input" placeholder="Password">
                  </div>
                  <div class="row">
                    <input @click="handleLogin" type="submit" value="Submit" class="btn">
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
  </template>
  
  <script>
  import axios from 'axios';
export default {
  name: 'LoginView',
  data() {
    return {
      username: '',
      password: '',
      rememberMe: false,
    };
  },
  methods: {
    async handleLogin() {
      try {
        // Assume you have an API endpoint for authentication
        const response = await axios.post('https://naturel-joli-432d8cf9d3d8.herokuapp.com/api/login', {
          username: this.username,
          password: this.password,
          rememberMe: this.rememberMe,
        });

        if (response.data.success) {
          this.$store.commit('setLoggedIn', true);
          console.log('Redirecting to /admin');
          this.$router.push('/admin');
        } else {
          console.error('Login failed');
        }
      } catch (error) {
        console.error('An error occurred during login:', error);
      }
    },
  },
};

  </script>
  
  <style scoped>
  .main-content {
    width: 50%;
    border-radius: 20px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4);
    margin: 5em auto;
    display: flex;
  }
  
  .company__info {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
  }
  
  .fa-android {
    font-size: 3em;
  }
  
  @media screen and (max-width: 640px) {
    .main-content {
      width: 90%;
    }
    .company__info {
      display: none;
    }
    .login_form {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }
  
  @media screen and (min-width: 642px) and (max-width: 800px) {
    .main-content {
      width: 70%;
    }
  }
  
  
  .login_form {
    background-color: #fff;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
  
  form {
    padding: 0 2em;
  }
  
  .form__input {
    width: 100%;
    border: 0px solid transparent;
    border-radius: 0;
    border-bottom: 1px solid #aaa;
    padding: 1em .5em .5em;
    padding-left: 2em;
    outline: none;
    margin: 1.5em auto;
    transition: all 0.5s ease;
  }
  
  .form__input:focus {
    border-bottom-color: #cd825b;
    box-shadow: 0 0 5px rgba(0, 80, 80, 0.4);
    border-radius: 4px;
  }
  
  .btn {
    transition: all 0.5s ease;
    width: 70%;
    border-radius: 30px;
    color: #cd825b;
    font-weight: 600;
    background-color: #fff;
    border: 1px solid #cd825b;
    margin-top: 1.5em;
    margin-bottom: 1em;
  }
  
  .btn:hover,
  .btn:focus {
    background-color: #cd825b;
    color: #fff;
  }
  </style>
  