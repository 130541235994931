import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import store from './store';



const app = createApp(App);
app.use(router);
app.use(store);
app.component('VueDatePicker', VueDatePicker);
app.mount('#app');

