<template>
    <div id="admin">
      <nav class="sidebar">
        <!-- Contenu de la barre de navigation à gauche -->
        <ul>
          <li><router-link to="/admin">Tableau de bord</router-link></li>
          <li><router-link to="/admin/client">Client</router-link></li>
          <li><router-link to="/admin/prestation">Prestations</router-link></li>
          <li><router-link to="/admin/revenue">Revenue</router-link></li>
        </ul>
      </nav>
      <main class="mb-5">
        <h1>Liste des Prestations</h1>
        <hr>
  
<!-- Formulaire pour ajouter une nouvelle prestation -->
<form @submit.prevent="ajouterPrestation" class="mt-4">
  <div class="mb-3">
    <label for="service" class="form-label">Service:</label>
    <input type="text" id="service" v-model="nouvellePrestation.service" class="form-control" required>
  </div>

  <div class="mb-3">
        <label for="categorie" class="form-label">Catégorie:</label>
        <select id="categorie" v-model="nouvellePrestation.categorie" class="form-select" required>
  <option value="" disabled>Choisissez une catégorie existante</option>
  <option v-for="categorie in categories" :key="categorie">{{ categorie }}</option>
  <option value="Autre">Autre</option>
</select>
      </div>

      <!-- Champ de texte pour entrer une nouvelle catégorie -->
      <div class="mb-3" v-if="nouvellePrestation.categorie === 'Autre'">
        <label for="nouvelleCategorie" class="form-label">Nouvelle Catégorie:</label>
        <input type="text" id="nouvelleCategorie" v-model="nouvellePrestation.nouvelleCategorie" class="form-control" required>
      </div>

      <div class="mb-3">
  <label for="duree" class="form-label">Durée:</label>
  <input type="time" id="duree" v-model="nouvellePrestation.duree" class="form-control" required>
</div>

<div class="mb-3">
  <label for="prix" class="form-label">Tarif:</label>
  <input type="number" id="prix" v-model="nouvellePrestation.prix" class="form-control" required>
</div>

  <button type="submit" class="btn btn-success">Ajouter Prestation</button>
</form>

  
<div class="pagination">
        <button @click="page -= 1" :disabled="page === 1">Précédent</button>
        <button @click="page += 1" :disabled="page * itemsPerPage >= prestations.length">Suivant</button>
      </div>

      <!-- Tableau des prestations existantes -->
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Catégorie</th>
            <th>Durée</th>
            <th>Prix</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <!-- Boucle sur les prestations à afficher sur la page actuelle -->
          <tr v-for="(prestation, index) in prestationsAffichees" :key="index">
            <td>
              <!-- Si la prestation est en mode édition, afficher un champ de saisie pour tous les champs -->
              <template v-if="prestation.enEdition">
                <input v-model="prestation.service" type="text" required>
              </template>
              <template v-else>
                {{ prestation.service }}
              </template>
            </td>
            <td>
              <!-- Si la prestation est en mode édition, afficher un champ de saisie pour tous les champs -->
              <template v-if="prestation.enEdition">
                <input v-model="prestation.categorie" type="text" required>
              </template>
              <template v-else>
                {{ prestation.categorie }}
              </template>
            </td>
            <td>
              <!-- Si la prestation est en mode édition, afficher un champ de saisie pour tous les champs -->
              <template v-if="prestation.enEdition">
                <input v-model="prestation.duree" type="text" required>
              </template>
              <template v-else>
                {{ prestation.duree }}
              </template>
            </td>
            <td>
              <!-- Si la prestation est en mode édition, afficher un champ de saisie pour tous les champs -->
              <template v-if="prestation.enEdition">
                <input v-model="prestation.prix" type="text" required>
              </template>
              <template v-else>
                €{{ prestation.prix }}
              </template>
            </td>
            <td>
              <button @click="toggleEditionMode(prestation)" class="btn btn-primary">
                {{ prestation.enEdition ? 'Enregistrer' : 'Modifier' }}
              </button>
              <button @click="supprimerPrestation(prestation._id)" class="btn btn-danger">Supprimer</button>
            </td>
          </tr>
        </tbody>
      </table>
    </main>
  </div>
</template>
  
  <script>
  export default {
    name: 'AdminPrestation',
    data() {
      return {
        prestations: [], // Initialiser la liste des prestations comme un tableau vide
        categories: [],
        nouvellePrestation: {
          service: '',
          categorie: '',
          nouvelleCategorie: '',
          duree: '',
          prix: '',
        },
        page: 1, // Page actuelle
      itemsPerPage: 10, // Nombre de prestations à afficher par page
      };
    },
    methods: {
        async ajouterPrestation() {
  try {
    let categorie = this.nouvellePrestation.categorie; // Récupérer la catégorie sélectionnée par l'utilisateur

    // Si la catégorie est "Autre", utiliser la nouvelle catégorie entrée par l'utilisateur
    if (categorie === 'Autre') {
      categorie = this.nouvellePrestation.nouvelleCategorie;
    }

    const nouvellePrestation = {
      service: this.nouvellePrestation.service,
      categorie: categorie,
      duree: this.nouvellePrestation.duree,
      prix: this.nouvellePrestation.prix,
    };

    const response = await fetch('https://naturel-joli-432d8cf9d3d8.herokuapp.com/prestations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(nouvellePrestation), // Utiliser la nouvelle structure
    });

    if (response.ok) {
  console.log('Prestation ajoutée avec succès');
  // Actualiser la liste des prestations
 
  // Réinitialiser le formulaire
  this.nouvellePrestation.service = '';
  this.nouvellePrestation.categorie = '';
  this.nouvellePrestation.nouvelleCategorie = '';
  this.nouvellePrestation.duree = '';
  this.nouvellePrestation.prix = '';
  this.recupererPrestations();
} else {
  console.error('Erreur lors de l\'ajout de la prestation');
}
    
  } catch (error) {
    console.error('Error adding prestation:', error);
  }
},

async recupererPrestations() {
  try {
    const response = await fetch('https://naturel-joli-432d8cf9d3d8.herokuapp.com/prestations');
    const data = await response.json();

    // Utilisez un Set pour stocker les catégories uniques
    const uniqueCategoriesSet = new Set();

    // Parcourez les prestations pour extraire les catégories uniques
    data.forEach((prestation) => {
      uniqueCategoriesSet.add(prestation.categorie);
    });

    // Convertissez le Set en tableau pour pouvoir l'utiliser dans le v-for
    this.categories = Array.from(uniqueCategoriesSet);

    // Enregistrez toutes les prestations
    this.prestations = data.map((prestation) => ({
      ...prestation,
      enEdition: false,
    }));
  } catch (error) {
    console.error('Error fetching prestations:', error);
  }
},

  
      toggleEditionMode(prestation) {
        // Inverser l'état du mode édition de la prestation
        prestation.enEdition = !prestation.enEdition;
  
        // Si le mode édition est désactivé, mettez à jour la prestation
        if (!prestation.enEdition) {
          this.mettreAJourPrestation(prestation);
        }
      },
  
      async mettreAJourPrestation(prestation) {
        try {

          const prestationToUpdate = {
            service: prestation.service,
            categorie: prestation.categorie,
            duree: prestation.duree,
            prix: prestation.prix,
          };

          console.log(prestationToUpdate);
          
          const response = await fetch(`https://naturel-joli-432d8cf9d3d8.herokuapp.com/prestations/${prestation._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(prestationToUpdate),
            
          });
          
  
          if (response.ok) {
            console.log(prestation),
            console.log('Prestation mise à jour avec succès');
            // Désactiver le mode édition
            prestation.enEdition = false;
            // Actualiser la liste des prestations
            this.recupererPrestations();
          } else {
            console.log(prestation),
            console.error('Erreur lors de la mise à jour de la prestation');
          }
        } catch (error) {
          console.error('Erreur lors de la mise à jour de la prestation:', error);
        }
      },
  
      async supprimerPrestation(id) {
        try {
          const response = await fetch(`https://naturel-joli-432d8cf9d3d8.herokuapp.com/prestations/${id}`, {
            method: 'DELETE',
          });
  
          if (response.ok) {
            console.log('Prestation supprimée avec succès');
            this.recupererPrestations();
          } else {
            console.error('Erreur lors de la suppression de la prestation');
          }
        } catch (error) {
          console.error('Error deleting prestation:', error);
        }
      },
    },
    mounted() {
      this.recupererPrestations();
    },
    computed: {
    // Calculer les prestations à afficher sur la page actuelle
    prestationsAffichees() {
      const startIndex = (this.page - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.prestations.slice(startIndex, endIndex);
    },
  },
  };
  </script>
  
  <style scoped>
  #admin {
    display: flex;
    flex-direction: row;
  }
  
  .sidebar {
    width: 206px;
    background-color: #333;
    color: white;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar li {
    padding: 10px;
  }
  
  .sidebar a {
    color: white;
    text-decoration: none;
  }
  
  main {
    flex: 1;
    padding: 20px;
  }

  .pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.pagination button {
  margin: 0 10px;
}
  </style>
  