<template>
  <div>
    <main>
      <!-- Premier conteneur pour le contenu principal -->
      <div class="container py-4">
    <div class="d-flex">
      <div class="d-flex flex-column align-items-start">
        <img alt="manucure" class="rounded-circle image1 " src="../asset/pexels-rodnae-productions-7755224.jpg">
      </div>

      <div class="flex-grow-1"></div>

      <div style="background: #efe5db;
border-radius: 50%;
width: 173px;
height: 173px;" class=" d-none d-md-flex d-flex flex-column align-items-end">
       
      </div>
    </div>

    <div class="d-flex justify-content-center align-items-center mt-4">
      <div class="d-flex flex-column align-items-center">
        <img alt="soin visage"  class="rounded-circle image1 grande-image" src="../asset/pexels-polina-tankilevitch-3738348.jpg">
        <img style="margin-top: -100px;" alt="pose vernis" class="rounded-circle image1 with-border" src="../asset/pexels-cottonbro-3997392.jpg">
      </div>
    </div>

  </div>
  <div class="d-flex flex-row-reverse">
  <h1 class="ml-auto p-4" style="font-size: 96px; font-weight: bold; font-style: italic; font-family: 'Playfair Display', serif;">
    Naturel  <br> & JOLI
  </h1>
</div>
  <hr style="border-width: 3px; color: rgb(205, 130, 91);">

      <!-- Deuxième conteneur pour le contenu principal -->
      <div class="container py-4 ">
    <div class="row">
      <div class="col-md-3 mb-2">
  <div class="card" style="height: 350px;">
    <div class="card-body d-flex flex-column justify-content-between">
      <div class="d-flex flex-column align-items-center">
        <i class="fas fa-icon fa-2x"></i>
        <h4 class="mt-2">Manucure</h4>
        <img style="height: 100px; width: 100px;" src="https://media3.giphy.com/media/MuHLvQ9B4ATCDdthHt/giphy.gif?cid=6c09b952vn7n9ct5ko8sz570yt6v7zqe2c0hu44ai28z3fiq&ep=v1_stickers_related&rid=giphy.gif&ct=s">
      </div>
      <p  class="flex-grow-1 ">Manucure, Pédicure de véritable soin et des poses de vernis hypoallergénique sans solvant</p>
      <button class="btn  btn-block button1">
        <router-link class="nav-link" to="/rendez-vous">RDV </router-link></button>
    </div>
  </div>
</div>
<div class="col-md-3 mb-2">
  <div class="card" style="height: 350px;">
    <div class="card-body d-flex flex-column justify-content-between">
      <div class="d-flex flex-column align-items-center">
        <i class="fas fa-icon fa-2x"></i>
        <h4 class="mt-2">Massage</h4>
        <img style="height: 100px; width: 100px;" src="https://media.giphy.com/avatars/hellej/KVvwuz8Yov4W.gif">
      </div>
      <p  class="flex-grow-1 mt-2">
        Massage aux ventouses à l’inspiration pure appréciez la différence avec des huiles naturelles BIO
      </p>
      <button class="btn  btn-block button1">
        <router-link class="nav-link" to="/rendez-vous">RDV </router-link></button>
    </div>
  </div>
</div>
<div class="col-md-3 mb-2">
  <div class="card" style="height: 350px;">
    <div class="card-body d-flex flex-column justify-content-between">
      <div class="d-flex flex-column align-items-center">
        <i class="fas fa-icon fa-2x"></i>
        <h4 class="mt-2">Soin Visage</h4>
        <img style="height: 100px; width: 100px;" src="https://i.pinimg.com/originals/c3/13/aa/c313aa0c7b8174e5b023202d36b429bd.gif">
      </div>
      <p  class="flex-grow-1 mt2 ">
        Redonnez de L’éclat a votre peau grâce à des produits Naturel et BIO avec Phyts
      </p>
      <button  class="btn  btn-block button1">
        <router-link class="nav-link" to="/rendez-vous">RDV </router-link></button>
    </div>
  </div>
</div>
<div class="col-md-3 mb-2">
  <div class="card" style="height: 350px;">
    <div class="card-body d-flex flex-column justify-content-between">
      <div class="d-flex flex-column align-items-center">
        <i class="fas fa-icon fa-2x"></i>
        <h4 class="mt-2">Epilations</h4>
        <img style="height: 100px; width: 100px;" src="https://media2.giphy.com/media/JJQXF1MOPId7CXKWkI/giphy.gif?cid=6c09b952vc335rjuxa79mzl6ygsfpsmlhd0ytw4ts8axzayh&ep=v1_internal_gif_by_id&rid=giphy.gif&ct=s">
      </div>
      <p  class="flex-grow-1 ">
        Epilation douce sans colophane mais efficace (adhère aux poils pas à la peau)
      </p>
      <button class="btn  btn-block button1">
        <router-link class="nav-link" to="/rendez-vous">RDV </router-link></button>
    </div>
  </div>
</div>
    </div>
    <div class="row">
    <div class="col-md-6 mb-2 offset-md-6 mb-5 mt-5" style="margin-top: 20px;">
      <div class="position-relative">
        <img src="../asset/pexels-andrea-piacquadio-3757942.jpg" alt="Image" class="img-fluid rounded-circle" style="width: 400px; height: 400px;">
      </div>
    </div>
    <div style="margin-top: -100px;" class="col-md-6 offset-md-3 mb-5">
      <div class="card position-relative">
        <div class="card-body">
          <h3>Bienvenue</h3>
          <p>Bienvenue dans mon nouveau concept bien-être au cœur du cabinet La Parenthèse de Taverny. Naturo-esthéticienne, C’est une vision globale de la beauté que je vous propose dans une démarche respectueuse de l’humain, de l’environnement et la biodiversité. Faites Une Pause Pour Une Expérience Différente.</p>
        </div>
      </div>
    </div>
  </div>
</div>

  <hr class="mt-3" style="border-width: 3px; color: rgb(205, 130, 91);">
  
      <!-- Troisième conteneur pour le contenu principal -->
      <div class="container py-4">
    <!-- Contenu principal de la troisième section -->
    <div class="row">
      <div class="col-md-6">
        <h4>Massage aux Ventouse Froides</h4>
        <p>
          Testez un massage vraiment différent.
La Ventouse et un outil de massage ancestral et d’excellence au service du bien-être. Son action en profondeur permet de libérer vos tensions, réguler vos flux énergétiques et de retrouvez une harmonie interne et externe.
        </p>
        <div class="d-flex justify-content-start">
          <img src="../asset/IMG_4469.jpg" alt="Image 1" class="rounded-circle" style="width: 175px; height: 175px;">
        </div>
      </div>
      <div class="col-md-6">
        
        <div class="d-flex justify-content-end mb-3">
          <img src="../asset/pexels-polina-tankilevitch-3738348.jpg" alt="Image 2" class="rounded-circle" style="width: 175px; height: 175px;">
        </div>
        <h4>Soin visage sur mesure et naturel</h4>
        <div class="d-flex justify-content-end mt-3">
          
          <p>
            Un diagnostic sur mesure et en profondeur grâce au VisioDerm , Mettez en lumière ce que votre peau vous réclame. Des soins, des cures et des produits cosmétiques personnalisés en adéquation à votre peau.

Le diagnostic VisioDerm vous est offert des 2 produits cosmétiques achetés.

(Hors stick à lèvre et SOS Imperfections)
          </p>
        </div>
      </div>
    </div>
  </div>


  <div style="background-color: #efe5db;">
   <!-- quatrieme conteneur pour le contenu principal -->
   <div  class="container py-4 mr-0 ml-0">
    <div class="row">
      <!-- Square image on the left -->
      <div class="col-md-6">
        <img src="../asset/visioderm.png" alt="Square Image" class="img-fluid" style="width: 500px; height: 600px;">
      </div>
      <!-- Right column with round image, text, and button -->
      <div class="col-md-6">
        <div class="d-flex flex-column align-items-center">
          <img src="../asset/phyts.jpg" alt="Round Image" class="rounded-circle" style="width:250px;height:250px;">
          <p class="mt-5 mb-5">La Nature a tant à nous offrir pourquoi chercher ailleurs ! les soin visage sont effectués avec des produits naturels et BIO Phyts. Soyez en adéquation avec votre peau pensez à votre routine Bio.</p>
          <button style="background-color: #cd825b; color: #efe5db;" class="btn  mt-2">En savoir plus</button>
        </div>
      </div>
    </div>
  </div>
</div>



       <!-- quatrieme conteneur pour le contenu principal -->

       <div class="container py-4">
        <div class="carousel mt-2" @mouseenter="stopAutoSlide" @mouseleave="startAutoSlide">
    <div class="carousel-content" :style="carouselStyle">
      <div class="carousel-slide" v-for="(logo, index) in logos" :key="index">
        <img style="height: 100px;" :src="logo" alt="Logo">
      </div>
    </div>
  </div>
  </div>

    </main>


  </div>
</template>

<script>
import cire from '../asset/Logo-Cire-Jolie-Paris-2.png'
import greenspa from '../asset/LOGOGREENSPA-removebg-preview.png'
import gelpolish from '../asset/gel-polish-removebg-preview.png'
import misencil from '../asset/Logo_PNG_picto_Misencil_3cb7dc6c-435b-4ff7-8ccd-99a33219f49f.png-3-removebg-preview.png'
export default {
  data() {
    return {
      currentSlide: 0,
      autoSlideInterval: null, // Store the interval ID
      logos: [
        'https://phyts.ch/wp-content/uploads/2020/08/logo-phyts-2.png',
        cire,
        greenspa,
        gelpolish,
        misencil,
        // Add more logo paths here
      ],
    };
  },
  computed: {
    carouselStyle() {
      return {
        transform: `translateX(-${this.currentSlide * 100}%)`,
      };
    },
  },
  methods: {
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.logos.length;
    },
    prevSlide() {
      this.currentSlide = (this.currentSlide - 1 + this.logos.length) % this.logos.length;
    },
    startAutoSlide() {
      this.autoSlideInterval = setInterval(this.nextSlide, 2000); // Change slides every 3 seconds
    },
    stopAutoSlide() {
      clearInterval(this.autoSlideInterval); // Stop automatic sliding
    },
  },
  mounted() {
    this.startAutoSlide(); // Start the automatic slideshow when the component is mounted
  },
};
</script>

<style scoped>
.image1 {
  width: 206px; 
  height: 206px;
  margin: 10px;
}

image2 {
  width: 206px; 
  height: 206px;
  margin: 10px;
}

.carousel {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.carousel-content {
  display: flex;
  transition: transform 0.5s;
}

.carousel-slide {
  flex-shrink: 0;
  width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.with-border {
  border: 5px solid white;
}

.bg-color {
  background-color: #f2f2f2; /* Change to your desired background color */
}

logo-img {
  max-width: 100%;
  height: auto;
  max-height: 100px; /* Adjust the maximum height as needed */
}
.button1 {
background-color: #efe5db;
border: 1px solid;
border-radius: 20px;
color: white;
text-align: bottom;
}

.button1:hover{
  background-color: #cd825b;
  color: #efe5db;
}

.grande-image {
  width: 500px;
  height: 500px;
}

@media (max-width: 767px) {
  .grande-image {
    width: 300px;
    height: 300px;
  }
}
</style>


