<template>
    <div id="admin">
      <nav class="sidebar">
        <!-- Contenu de la barre de navigation à gauche -->
        <ul>
          <li><router-link to="/admin">Tableau de bord</router-link></li>
          <li><router-link to="/admin/client">Client</router-link></li>
          <li><router-link to="/admin/prestation">Prestations</router-link></li>
          <li><router-link to="/admin/revenue">Revenue</router-link></li>
        </ul>
      </nav>
      <main>
        <!-- Ajout du titre "Calendrier" -->
        <h1>Calendrier</h1>
        <!-- Ajout d'une ligne horizontale -->
        <hr>
        <!-- Bouton pour afficher le formulaire -->
        <div>
          <div>
          <button @click="showForm = true" class="btn btn-primary">Créer un événement</button>
          <button @click="showFormVacace = true" class="btn btn-primary">Ajouter des vacances</button>
          <div v-if="showFormVacace" class="overlay">
            <div class="container mt-5 overlay">
  <div class="popup">
    <!-- Formulaire d'ajout de vacances -->
    <div class="mb-3">
        <label for="title" class="form-label">Titre</label>
        <input type="text" id="title" v-model="title" class="form-control" required>
      </div>
    <form @submit.prevent="setVacation" class="mb-3 d-flex justify-content-between align-items-center">
      <div class="mb-3">
        <label for="startDate" class="form-label">Date de début:</label>
        <input type="date" id="startDate" v-model="startDate" class="form-control" required>
      </div>

      <div class="mb-3">
        <label for="startTime" class="form-label">Heure de début:</label>
        <input type="time" id="startTime" v-model="startTime" class="form-control" required>
      </div>

      <div class="mb-3">
        <label for="endDate" class="form-label">Date de fin:</label>
        <input type="date" id="endDate" v-model="endDate" class="form-control" required>
      </div>

      <div class="mb-3">
        <label for="endTime" class="form-label">Heure de fin:</label>
        <input type="time" id="endTime" v-model="endTime" class="form-control" required>
      </div>

      

      <button type="submit" class="btn btn-primary">Ajouter des vacances</button>
    </form>

    <!-- Formulaire de suppression de vacances -->
    <form @submit.prevent="deleteVacation" class="mb-3 d-flex justify-content-between align-items-center">
      <div class="mb-3">
        <label for="deleteStartDate" class="form-label">Date de début:</label>
        <input type="date" id="deleteStartDate" v-model="deleteStartDate" class="form-control" required>
      </div>

      <div class="mb-3">
        <label for="startTimeDel" class="form-label">Heure de début:</label>
        <input type="time" id="startTimeDel" v-model="startTimeDel" class="form-control" required>
      </div>

      <div class="mb-3">
        <label for="deleteEndDate" class="form-label">Date de fin:</label>
        <input type="date" id="deleteEndDate" v-model="deleteEndDate" class="form-control" required>
      </div>

      <div class="mb-3">
        <label for="endTimeDel" class="form-label">Heure de fin:</label>
        <input type="time" id="endTimeDel" v-model="endTimeDel" class="form-control" required>
      </div>

      <button type="submit" class="btn btn-danger">Supprimer des vacances</button>
    </form>

    <!-- Bouton de fermeture -->
    <button @click="showFormVacace = false" class="btn btn-warning">Fermer</button>
  </div>
</div>

  </div>

<!-- Superposition -->
<div v-if="showForm" class="overlay">
  <!-- Popup -->
  <div class="popup">
    <div class="popup-content">
      <h5 class="modal-title">Créer un événement</h5>
      <button type="button" class="close" @click="closeForm" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
          </div>
          <div class="modal-body">
            <!-- Votre formulaire ici -->
            <div class="form-group">
              <label for="category">Catégorie:</label>
              <select class="form-control" v-model="selectedCategory" @change="filterServices">
      <option value="">Sélectionnez une catégorie</option>
      <option v-for="category in categories" :key="category" :value="category">
        {{ category }}
      </option>
    </select>

    <!-- Deuxième liste déroulante pour les services filtrés par catégorie -->
    <label for="services">Service:</label>
    <select class="form-control" v-model="selectedService" :disabled="!selectedCategory">
      <option value="">Sélectionnez un service</option>
      <option v-for="service in filteredServices" :key="service" :value="service">
        {{ service.service }} 
      </option>
    </select>
            </div>
            <div class="form-group">
              <label for="firstName">Prénom:</label>
              <input type="text" id="firstName" class="form-control" v-model="firstName">
            </div>
            <div class="form-group">
              <label for="lastName">Nom:</label>
              <input type="text" id="lastName" class="form-control" v-model="lastName">
            </div>
            <div class="form-group">
              <label for="phone">Telephone:</label>
              <input type="tel" id="phone" class="form-control" v-model="phone">
            </div>
            <div class="form-group">
    <label for="email">Email</label>
    <input type="email" class="form-control" id="email" v-model="email" required>
  </div>
            <div class="form-group">
              <label for="eventDateTime">Date et heure de l'événement:</label>
              <input type="datetime-local" id="eventDateTime" class="form-control" v-model="eventDateTime">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeForm">Fermer</button>
            <button type="button" class="btn btn-primary" @click="createEvent">Créer l'événement</button>
          </div>
        </div>
      </div>
      <div v-if="showEventPopup" class="overlay">
      <div class="popup">
        <!-- Popup content and information -->
        <h5 class="modal-title"> Information RDV</h5>
        <!-- Display relevant information from the selected event -->
        <p>Title: {{ selectedEvent.title }}</p>
        <p>Debut Prestation: {{ formatDateTime(selectedEvent.start) }}</p>
        <p>Services:</p>

  <div class="mb-2" style="" v-for="service in selectedEvent.services" :key="service._id">{{ service.name }}  <button type="button" v-if="isEditMode" class="btn btn-danger" @click="deleteService(index)">Supprimer</button></div>
   

<div  v-if="isEditMode" >
<label for="categories">Catégorie:</label>
    <select v-model="selectedCategory" @change="filterServices">
      <option value="">Sélectionnez une catégorie</option>
      <option v-for="category in categories" :key="category" :value="category">
        {{ category }}
      </option>
    </select>

    <!-- Deuxième liste déroulante pour les services filtrés par catégorie -->
    <label for="services">Service:</label>
    <select v-model="selectedService" :disabled="!selectedCategory">
      <option value="">Sélectionnez un service</option>
      <option v-for="service in filteredServices" :key="service" :value="service">
        {{ service.service }} 
      </option>
    </select>
    <button type="button" class="btn btn-success" @click="addService">Ajouter</button>

  </div>

        <p>Fin Prestation: {{ formatDateTime(selectedEvent.end) }}</p>
        <p>duree : {{ selectedEvent.duration }}</p>
        <p>Total: {{ selectedEvent.totalPrice }} € </p>
        <!-- Add more details as needed -->

        <!-- Close button -->
        <button type="button" style="margin-right: 2px;" class="btn btn-primary" @click="closeEventPopup">Fermer</button>
        <button type="button" style="margin-right: 2px;" class="btn btn-danger" @click="deleteEvent">Supprimer</button>
        <button type="button" style="margin-right: 2px;" class="btn btn-primary  " @click="editmode"  >Modifier</button>
      </div>
    </div>
    </div>
  </div>

      
        
        
  
        <!-- Contenu principal de la page d'administration -->
        <!-- Intégration du calendrier FullCalendar avec une hauteur personnalisée -->
        <div class="container mt-4">
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Calendrier</h2>
      </div>
      <div class="card-body">
        <FullCalendar :options="calendarOptions" ref="fullCalendarRef" />
      </div>
    </div>
  </div>
      </main>
    </div>
  </template>
  
  <script>
  // Importez le composant FullCalendar
  import FullCalendar from '@fullcalendar/vue3';
  import { DateTime } from 'luxon';
  import dayGridPlugin from '@fullcalendar/daygrid';
  import bootstrap5Plugin from '@fullcalendar/bootstrap5';
  import timeGridPlugin from '@fullcalendar/timegrid';
  import listPlugin from '@fullcalendar/list';
  import frLocale from '@fullcalendar/core/locales/fr';
  import interactionPlugin from '@fullcalendar/interaction';
  import 'bootstrap-icons/font/bootstrap-icons.css';
  import axios from 'axios';
  import moment from 'moment';
  
  export default {
    name: 'AdminVues',
    components: {
      FullCalendar,
    },
    data() {
      return {
        startTime: '',
        title: '',
        showFormVacace: false,
        endTime: '',
        startTimeDel: '',
        endTimeDel: '',
        
        editMode: false,
        prestationList: [],
        startDate: '',
      endDate: '',
      deleteStartDate: '',
      deleteEndDate: '',
        email: '',
        isEditMode: false,
        showForm: false,
        showEventPopup: false,

// Additional data property for the selected event in the popup
selectedEvent: {},
        
        calendarOptions: {
          plugins: [dayGridPlugin, bootstrap5Plugin, timeGridPlugin, listPlugin, interactionPlugin], // Activez les plugins nécessaires
          themeSystem: 'bootstrap5',
          selectedPrestation: '',
          eventOverlap: false,
      input2: '',
      input3: '',
          initialView: 'timeGridWeek',
          refetchResourcesOnNavigate: true,
          events: 'https://naturel-joli-432d8cf9d3d8.herokuapp.com/appointments',
          editable: true,
          eventClick: function(info) {
    alert('Event: ' + info.event.title);
    alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
    alert('View: ' + info.view.type);

    // change the border color just for fun
    info.el.style.borderColor = 'red';
  },
          droppable: true,
          
          eventStartEditable: true,
          eventResizableFromStart: true,
          slotMinTime: '08:00:00',
          slotMaxTime: '21:00:00',
          eventDrop: this.handleEventDrop,
          eventResize: this.handleEventResize,
          locale: frLocale,
          headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
          },
          businessHours: {
            // jours de la semaine. un tableau d'entiers de jour de la semaine à base zéro (0=dimanche)
            daysOfWeek: [1, 2, 3, 4, 5, 6], // Lundi - Jeudi
            startTime: '9:00', // Heure de début (par exemple, 10h dans cet exemple)
            endTime: '20:00', // Heure de fin (par exemple, 18h dans cet exemple)
          },
          height: '600px',
          navLinks: true,
          nowIndicator: true, // Indicateur "maintenant"
        },
        prestations: [],
        events: [],         // Contrôle l'affichage du formulaire
        selectedCategory: '',     // Catégorie sélectionnée
        selectedService: '',      // Service sélectionné
        firstName: '',            // Prénom
        lastName: '',             // Nom
        eventDateTime: '',  
        
        phone : '',// Date et heure de l'événement
      };
    },

    
    
    methods: {

      async setVacation() {
      try {
        const startDateTime = DateTime.fromISO(`${this.startDate}T${this.startTime}`);
        const endDateTime = DateTime.fromISO(`${this.endDate}T${this.endTime}`);

        const response = await axios.post('https://naturel-joli-432d8cf9d3d8.herokuapp.com/set-vacation', {
          startDate: startDateTime.toISO(),
          endDate: endDateTime.toISO(),
          title: this.title,
        });

        this.startDate = '';
        this.startTime = '';
        this.endDate = '';
        this.endTime = '';

        console.log(response.data.message);
      } catch (error) {
        console.error('Erreur lors de la définition des vacances :', error);
      }
    },

    async deleteVacation() {
      try {
        const startDateTime = DateTime.fromISO(`${this.deleteStartDate}T${this.startTimeDel}`);
        const endDateTime = DateTime.fromISO(`${this.deleteEndDate}T${this.endTimeDel}`);

        const response = await axios.delete('https://naturel-joli-432d8cf9d3d8.herokuapp.com/delete-vacation', {
          data: {
            startDate: startDateTime.toISO(),
            endDate: endDateTime.toISO(),
          },
        });

        this.deleteStartDate = '';
        this.deleteEndDate = '';

        console.log(response.data.message);
      } catch (error) {
        console.error('Erreur lors de la suppression des vacances :', error);
      }
    },

    
      async createEvent() {
  try {

    const eventDateTime = this.eventDateTime;
    const calendarApi = this.$refs.fullCalendarRef.getApi();

    const selectedPrestation = this.prestations.find(prestation => prestation.service === this.selectedService.service);
    console.log(' Prestation:', this.prestations);
    console.log('Selected Event:', this.selectedEvent);

    if (selectedPrestation) {
      const serviceDuree = selectedPrestation.duree;
      const servicePrix = selectedPrestation.prix;
      const [hours, minutes] = serviceDuree.split(':').map(Number);

      const eventStartDateTime = new Date(eventDateTime);
      eventStartDateTime.setHours(eventStartDateTime.getHours() + hours);
      eventStartDateTime.setMinutes(eventStartDateTime.getMinutes() + minutes);

      const requestData = {
        title: `${this.firstName} ${this.lastName}`,
        start: eventDateTime,
        end: eventStartDateTime.toISOString(), // Convert to ISO string
        category: this.selectedCategory,
        service: [{ name: this.selectedService.service, price: servicePrix }],
        price: servicePrix,
        phone: this.phone,
        email: this.email, // Use an array for the "service" field
        nom: this.lastName,
        prenom: this.firstName,
      };

      // Send an HTTP POST request to your backend API to create the event
      const response = await axios.post('https://naturel-joli-432d8cf9d3d8.herokuapp.com/appointments', [requestData]);

      console.log('Event created successfully:', response.data);
      
      // Add the newly created event to the FullCalendar
      calendarApi.addEvent(requestData);

      // Clear form fields
      this.selectedCategory = '';
      this.selectedService = '';
      this.firstName = '';
      this.lastName = '';
      this.eventDateTime = '';
      this.showForm = false;
    }
  } catch (error) {
    console.error('Error creating event:', error);
    // Handle errors as needed
  }
}
,

editEvent() {
    // Mettez en œuvre la logique pour modifier les informations du rendez-vous ici
    // Vous pouvez utiliser les données de selectedEvent pour pré-remplir le formulaire de modification, par exemple
    this.showEditForm = true; // Assurez-vous d'avoir une variable showEditForm dans vos données pour afficher le formulaire de modification
  },

  editmode(){
    this.isEditMode = !this.isEditMode;
  },
  

  fetchAppointments() {
    axios.get('https://naturel-joli-432d8cf9d3d8.herokuapp.com/appointments')
      .then(response => {
        // Transformez les données de rendez-vous au format FullCalendar
        const appointments = response.data.map(appointment => ({
          id: appointment._id,
          title: appointment.title,
          start: appointment.start,
          end: appointment.end,
        }));

        this.appointments = appointments;


        this.events = appointments; // Mettez les rendez-vous dans la propriété "events"
        console.log('Appointments:');
        console.log(this.events);
      })
      .catch(error => {
        console.error('Error fetching appointments:', error);
      });
  },

  closeForm() {
      this.showForm = false;
    },

  prestationSelected() {
      const prestation = this.prestations.find(prestation => prestation.id === this.selectedPrestation);
      if (prestation) {
        this.input2 = prestation.heure; // Remplacez par le nom de la propriété correcte de la prestation
        this.input3 = prestation.heure_souhaitée; // Remplacez par le nom de la propriété correcte de la prestation
      }
    },

      updateDuree() {
    // Recherchez la durée du service sélectionné dans les données récupérées
    const selectedPrestation = this.prestations.find(prestation => prestation.service === this.nouvellePrestation.service);

    // Mettez à jour la durée du formulaire avec la durée du service trouvé
    if (selectedPrestation) {
      this.nouvellePrestation.duree = selectedPrestation.duree;
    }
  },

  closeModal() {
      // Emit an event to inform the parent component to close the modal
      this.$emit('close');
    },
    handleEventDrop(info) {
  console.log('Event dropped:', info.event.title);
  console.log('New start date:', info.event.start);
  console.log('New end date:', info.event.end);
  console.log('ID:', info.event.id);
  this.selectedEvent = {
      title: info.event.title,
      start: info.event.start,
      end: info.event.end,
      id: info.event.id,
      services: info.event.extendedProps.service,
      duration: this.calculateDuration(info.event.start, info.event.end),
    };

    // Log the selected event
    console.log('Selected Event:', this.selectedEvent);

    // Create a temporary list of service names
    this.temporaryServiceList = this.selectedEvent.services.map(service => service.name);

    this.selectedEvent.services = this.selectedEvent.services.map(service => ({
      ...service,
      price: this.calculateServicePrice(service),
    }));

    // Log the temporary service list
    console.log('Temporary Service List:', this.temporaryServiceList);

    // Calculate the total price
    this.calculateTotalPrice();


  this.updateAppointment();
}
,

handleEventResize(info) {
  console.log('Event resized:', info.event.title);
  console.log('New start date:', info.event.start);
  console.log('New end date:', info.event.end);
  this.selectedEvent = {
      title: info.event.title,
      start: info.event.start,
      end: info.event.end,
      id: info.event.id,
      services: info.event.extendedProps.service,
      duration: this.calculateDuration(info.event.start, info.event.end),
    };

    // Log the selected event
    console.log('Selected Event:', this.selectedEvent);

    // Create a temporary list of service names
    this.temporaryServiceList = this.selectedEvent.services.map(service => service.name);

    this.selectedEvent.services = this.selectedEvent.services.map(service => ({
      ...service,
      price: this.calculateServicePrice(service),
    }));

    // Log the temporary service list
    console.log('Temporary Service List:', this.temporaryServiceList);

    // Calculate the total price
    this.calculateTotalPrice();


  this.updateAppointment();


},

handleEventClick(info) {
    // Set the selected event data
    this.selectedEvent = {
      title: info.event.title,
      start: info.event.start,
      end: info.event.end,
      id: info.event.id,
      services: info.event.extendedProps.service,
      duration: this.calculateDuration(info.event.start, info.event.end),
    };

    // Log the selected event
    console.log('Selected Event:', this.selectedEvent);

    // Create a temporary list of service names
    this.temporaryServiceList = this.selectedEvent.services.map(service => service.name);

    this.selectedEvent.services = this.selectedEvent.services.map(service => ({
      ...service,
      price: this.calculateServicePrice(service),
    }));

    // Log the temporary service list
    console.log('Temporary Service List:', this.temporaryServiceList);

    // Calculate the total price
    this.calculateTotalPrice();

    // Show the popup
    this.showEventPopup = true;
  },
  calculateTotalPrice() {
    if (this.temporaryServiceList && this.temporaryServiceList.length > 0) {
      // Sum up the prices for all services in the temporary list
      const totalPrice = this.temporaryServiceList.reduce((total, serviceName) => {
  const matchingPrestation = this.prestations.find(prestation => prestation.service === serviceName);

  console.log(`Service: ${serviceName}, Matching Prestation:`, matchingPrestation);

  // Convert the price from string to number
  const price = matchingPrestation ? Number(matchingPrestation.prix) : 0;

  // Add the price of the service to the total
  return total + price;
}, 0);

// Set the total price in your data property or use it as needed
this.selectedEvent.totalPrice = totalPrice;
console.log('Total Price:', totalPrice);
    }
  },
  calculateServicePrice(service) {
    // Find the corresponding service in the prestations data
    const matchingPrestation = this.prestations.find(prestation => prestation.service === service.name);

    console.log(`Service: ${service.name}, Matching Prestation:`, matchingPrestation);
    console.log('Prix:', matchingPrestation.prix);

    // Return the price of the service, or 0 if no matching prestation is found
    return matchingPrestation ? matchingPrestation.prix : 0;
    
  },
  updateTotalPrice() {
    if (this.selectedEvent.services && this.selectedEvent.services.length > 0) {
      // Sum up the prices for all services in the selected event
      const totalPrice = this.selectedEvent.services.reduce((total, service) => {
        // Find the corresponding service in the prestations data
        const matchingPrestation = this.prestations.find(prestation => prestation.service === service.name);

        console.log(`Service: ${service.name}, Matching Prestation:`, matchingPrestation);

        // Add the price of the service to the total
        return total + (matchingPrestation ? matchingPrestation.prix : 0);
      }, 0);

      // Set the total price in your data property or use it as needed
      this.selectedEvent.totalPrice = totalPrice;
      console.log('Updated Total Price:', totalPrice);
    }
  },
  addService() {
   
      // Vérifiez si la catégorie et le service sélectionnés sont définis
      if (this.selectedCategory && this.selectedService) {
        const matchingPrestation = this.prestations.find(prestation => prestation.service === this.selectedService.service);
        // Créez un nouvel objet service à partir des valeurs sélectionnées
        const newService = {
          name: this.selectedService.service,
          category: this.selectedCategory,
          price: matchingPrestation.prix,
          // Ajoutez d'autres propriétés si nécessaires
        };

        // Ajoutez le nouveau service à la liste des services de l'événement sélectionné
        this.selectedEvent.services.push(newService);

        // Appelez la méthode updateAppointment pour mettre à jour les données côté serveur
        this.updateTotalPrice();
        this.updateDuration();
        this.updateAppointment();
        
      } else {
        // Gérez le cas où la catégorie ou le service n'est pas sélectionné
        console.error('Veuillez sélectionner une catégorie et un service.');
        // Vous pouvez afficher un message à l'utilisateur ou prendre d'autres mesures nécessaires
      }
    },
  


    // Method to close the popup
    closeEventPopup() {
      // Clear the selected event data
      this.selectedEvent = {};

      // Hide the popup
      this.showEventPopup = false;
    },
    calculateDuration(start, end) {
    const startTime = DateTime.fromJSDate(start);
    const endTime = DateTime.fromJSDate(end);
    const duration = endTime.diff(startTime, ['hours', 'minutes']).toObject();

    return `${duration.hours} hours ${duration.minutes} minutes`;
  },

  async deleteEvent() {
  const eventId = this.selectedEvent.id;

  // Check if selectedEvent is defined and has the expected properties
  if (!this.selectedEvent || !this.selectedEvent.title) {
    console.error('Selected event is undefined or missing title. Unable to delete event.');
    return;
  }

  // Extract the client's name and surname from the title
  const [nom, prenom] = this.selectedEvent.title.split(' ');
  console.log('Nom:', nom);

  try {
    // Make an HTTP DELETE request to your backend to delete the event
    const deleteResponse = await axios.delete(`https://naturel-joli-432d8cf9d3d8.herokuapp.com/appointments/${eventId}`);
    console.log('Event deleted successfully:', deleteResponse.data);

    // Make an HTTP GET request to the /clients route to find the email
    const clientResponse = await axios.get(`https://naturel-joli-432d8cf9d3d8.herokuapp.com/clients?nom=${prenom}&prenom=${nom}`);
    // Assuming there is only one matching client, get the first client in the response
    const matchingClient = clientResponse.data[0];

    if (!matchingClient) {
      console.error('No matching client found for deletion.');
      return;
    }

    // Extract relevant client information
    const { email } = matchingClient;

    console.log('Client Email:', email);

    // Send confirmation email
    const emailData = {
      to: email, 
      cc: 'natureletjoli@gmail.com',
      subject: 'Annulation RDV Confirmation',
      body: 'Votre Rendez vous a été annuler avec succes',
    };

    const emailResponse = await axios.post('https://naturel-joli-432d8cf9d3d8.herokuapp.com/sendConfirmationEmail', emailData);
    console.log('Email confirmation sent successfully:', emailResponse.data);

    // Close the popup after deletion
    this.closeEventPopup();
    // Optionally, you can refresh the events on the calendar after deletion
    this.$refs.fullCalendarRef.getApi().refetchEvents();
  } catch (error) {
    console.error('Error deleting event or sending email confirmation:', error);
    // Handle errors as needed
  }
}

,



    formatDateTime(dateTime) {
      return DateTime.fromJSDate(dateTime).toLocaleString({
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
    },

    async updateAppointment() {
  const appointmentId = this.selectedEvent.id; // Assuming your event has an id

  try {
    // Extract the client's name and surname from the title
    const [nom, ...prenomArray] = this.selectedEvent.title.split(' ');
const prenom = prenomArray.join(' ');
console.log('Nom:', prenom);
console.log('Prenom:', nom);

    // Make an HTTP GET request to the /clients route to find the email
    const clientResponse = await axios.get(`https://naturel-joli-432d8cf9d3d8.herokuapp.com/clients?nom=${prenom}&prenom=${nom}`);
    console.log('Client Response:', clientResponse);
    
    // Assuming there is only one matching client, get the first client in the response
    const matchingClient = clientResponse.data[0];
    console.log('Matching Client:', matchingClient);

    if (!matchingClient) {
      console.error('No matching client found.');
      return;
    }

    // Extract relevant client information
    const { email : email, nom: clientNom, prenom: clientPrenom } = matchingClient;
    

    console.log('Client Email:', email);
    console.log('selectedEvent', this.selectedEvent);

    // Prepare the updated data for the appointment
    const updatedData = {
      title: this.selectedEvent.title,
      start: this.selectedEvent.start,
      end: this.selectedEvent.end,
      service: this.selectedEvent.services.map(service => ({
        name: service.name,
        price: parseInt(service.price),
      })),
      nom: clientNom,
      prenom: clientPrenom,
      email: email,
      price: this.selectedEvent.totalPrice,
    };

    console.log('updateddata:', updatedData);

    console.log('Updated Appointment Data:', updatedData);

    // Make an HTTP PUT request to update the appointment
    const response = await axios.put(`https://naturel-joli-432d8cf9d3d8.herokuapp.com/appointments/${appointmentId}`, updatedData);

    console.log('Appointment updated successfully:', response.data);

    // Optionally, you can refresh the events on the calendar after updating
    this.$refs.fullCalendarRef.getApi().refetchEvents();
    // Close the popup after updating
  } catch (error) {
    console.error('Error updating appointment:', error);
    // Handle errors as needed
  }
},






    deleteService(index) {
    this.selectedEvent.services.splice(index, 1);
    this.updateDuration();
    this.updateTotalPrice();
    this.updateAppointment();
  },

  // Method to toggle edit mode
  toggleEditMode() {
      this.editMode = !this.editMode;
    },


  filterServices() {
      // Réinitialiser le service sélectionné lorsque la catégorie change
      this.selectedService = '';
    },

   

   
    async updateDuration() {
  try {
    // Vérifiez si l'événement sélectionné a une liste de services
    if (this.selectedEvent.services && this.selectedEvent.services.length > 0) {
      // Récupérez la liste complète des services depuis votre API
      const response = await axios.get('https://naturel-joli-432d8cf9d3d8.herokuapp.com/prestations');
      const allServices = response.data;

      // Utilisez reduce pour calculer la somme des durées des services
      const totalDuration = this.selectedEvent.services.reduce(
        (accumulator, service) => {
          // Recherchez le service correspondant dans la liste complète
          const matchingService = allServices.find(s => s.service === service.name);

          // Ajoutez la durée du service correspondant à l'accumulateur
          return accumulator + (matchingService ? this.convertDurationToMinutes(matchingService.duree) : 0);
        },
        0
      );

      // Mettez à jour la durée de l'événement avec la durée totale des services en minutes
      this.selectedEvent.duration = totalDuration;

      // Mettez à jour l'heure de fin (end) en fonction de la durée calculée
      const startMoment = moment(this.selectedEvent.start);
      const endMoment = startMoment.clone().add(totalDuration, 'minutes');
      this.selectedEvent.end = endMoment.toISOString();

      // Appelez la méthode updateAppointment pour mettre à jour les données côté serveur
      this.updateAppointment();
    } else {
      // Gérez le cas où aucun service n'est disponible
      console.error('Aucun service disponible.');
      // Vous pouvez afficher un message à l'utilisateur ou prendre d'autres mesures nécessaires
    }
  } catch (error) {
    console.error('Erreur lors de la récupération des services :', error);
  }
},


formatDuration(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}h${minutes !== 0 ? minutes + 'min' : ''}`;
},


convertDurationToMinutes(duration) {
  const [hours, minutes] = duration.split(':');
  return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
},


  

      

      
    },

    computed: {
    // Filtrer les services en fonction de la catégorie sélectionnée
    filteredServices() {
     console.log('services', this.prestationList);
     console.log('selectedCategory', this.selectedCategory);
     console.log('service', this.selectedService)
     return this.prestationList.filter(prestation => prestation.categorie === this.selectedCategory);
},
  },
    mounted() {

      this.$refs.fullCalendarRef.getApi().setOption('eventClick', this.handleEventClick);

      console.log('events : ',this.events)
    // Récupérez les catégories et les services lorsque le composant est monté
    axios.get('https://naturel-joli-432d8cf9d3d8.herokuapp.com/prestations')
  .then(response => {
    // Traitez les données de réponse et stockez-les dans les propriétés de données appropriées
    const prestations = response.data;
    this.prestations = prestations;

    // Créer un ensemble unique de catégories
    const categoriesSet = new Set(prestations.map(prestation => prestation.categorie));
    const categories = Array.from(categoriesSet);

   

    // Stockez les catégories et les services dans les propriétés de données
    const prestationList = prestations.map(prestation => ({
        categorie: prestation.categorie,
        service: prestation.service,
        id: prestation._id,
      }));

      // Stockez les catégories, les services et la liste de prestations dans les propriétés de données
      this.categories = categories;
      this.services = prestations.map(prestation => prestation.service);
      this.prestationList = prestationList;
    
    
    console.log(this.id);
    console.log('prestation', this.prestations);
  })
  .catch(error => {
    console.error('Une erreur s\'est produite lors de la récupération des données :', error);
  });


      this.fetchAppointments();
  },
  };
  </script>
  
  <style scoped>
  #admin {
    display: flex;
    flex-direction: row;
  }
  
  .sidebar {
    width: 206px;
    background-color: #333;
    color: white;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar li {
    padding: 10px;
  }
  
  .sidebar a {
    color: white;
    text-decoration: none;
  }
  
  main {
    flex: 1;
    padding: 20px;
  }

  .popup-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  text-align: center;
}
  
  .event-form {
    margin-top: 20px;
  }

  /* Styles pour la fenêtre modale */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: auto;
}

.modal-content {
  background-color: #fff;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Couleur de fond semi-transparente */
  z-index: 999;
  /* Place la superposition au-dessus de tout le contenu */
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-slot-item {
    vertical-align: top;
  }
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  /* Couleur de fond du popup */
  z-index: 1000;
  /* Place le popup au-dessus de la superposition */
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}



  </style>
  