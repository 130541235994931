<template>
    <div id="admin">
      <nav class="sidebar">
        <!-- Contenu de la barre de navigation à gauche -->
        <ul>
          <li><router-link to="/admin">Tableau de bord</router-link></li>
          <li><router-link to="/admin/client">Client</router-link></li>
          <li><router-link to="/admin/prestation">Prestations</router-link></li>
          <li><router-link to="/admin/revenue">Revenue</router-link></li>
        </ul>
      </nav>
      <main class="mb-5">
      <h1>Liste des Clients</h1>
      <div class="mb-3">
        <!-- Search bar -->
        <input v-model="searchQuery" @input="searchClients" class="form-control" placeholder="Rechercher un client">
      </div>
      <hr class="mb-5">
      <table class="table table-striped mt-3">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Email</th>
            <th>Téléphone</th>
            <th>Nombre de Visites</th>
          </tr>
        </thead>
        <tbody>
          <!-- Boucle sur les clients filtrés pour afficher chaque client -->
          <tr v-for="(client, index) in filteredClients" :key="index">
            <td>{{ client.nom }}</td>
            <td>{{ client.prenom }}</td>
            <td>{{ client.email }}</td>
            <td>{{ client.phone }}</td>
            <td>{{ client.count }}</td>
          </tr>
        </tbody>
      </table>
    </main>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ClientVues',
  data() {
    return {
      clients: [],
      searchQuery: '', // Added searchQuery data property
    };
  },
  computed: {
    // Filtered clients based on search query
    filteredClients() {
    return this.clients.filter(client => {
      // Check if client has 'nom' property before accessing it
      const hasNom = client.nom && typeof client.nom === 'string';

      return (
        (hasNom && client.nom.toLowerCase().includes(this.searchQuery.toLowerCase())) ||
        (client.prenom && client.prenom.toLowerCase().includes(this.searchQuery.toLowerCase())) ||
        (client.email && client.email.toLowerCase().includes(this.searchQuery.toLowerCase())) ||
        (client.phone && client.phone.toLowerCase().includes(this.searchQuery.toLowerCase()))
      );
    });
  },
  },
  mounted() {
    // Fetch the list of clients when the component is mounted
    this.fetchClients();
  },
  methods: {
    async fetchClients() {
      try {
        // Make a GET request to fetch the list of clients
        const response = await axios.get('https://naturel-joli-432d8cf9d3d8.herokuapp.com/clients');

        // Update the clients data property with the received data
        this.clients = response.data;
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    },
    // Triggered when the user types in the search bar
    searchClients() {
      // You can debounce this function to delay the search if needed
    },
  },
};
</script>

<style scoped>
#admin {
  display: flex;
  flex-direction: row;
}

.sidebar {
  width: 206px;
  background-color: #333;
  color: white;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  padding: 10px;
}

.sidebar a {
  color: white;
  text-decoration: none;
}

main {
  flex: 1;
  padding: 20px;
}
</style>
  